import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Announcement = () => import('@/entities/announcement/announcement.vue');
// prettier-ignore
const AnnouncementUpdate = () => import('@/entities/announcement/announcement-update.vue');
// prettier-ignore
const AnnouncementDetails = () => import('@/entities/announcement/announcement-details.vue');
// prettier-ignore
const AnnouncementLicense = () => import('@/entities/announcement-license/announcement-license.vue');
// prettier-ignore
const AnnouncementLicenseUpdate = () => import('@/entities/announcement-license/announcement-license-update.vue');
// prettier-ignore
const AnnouncementLicenseDetails = () => import('@/entities/announcement-license/announcement-license-details.vue');
// prettier-ignore
const Company = () => import('@/entities/company/company.vue');
// prettier-ignore
const CompanyUpdate = () => import('@/entities/company/company-update.vue');
// prettier-ignore
const CompanyDetails = () => import('@/entities/company/company-details.vue');
// prettier-ignore
const Country = () => import('@/entities/country/country.vue');
// prettier-ignore
const CountryUpdate = () => import('@/entities/country/country-update.vue');
// prettier-ignore
const CountryDetails = () => import('@/entities/country/country-details.vue');
// prettier-ignore
const Event = () => import('@/entities/event/event.vue');
// prettier-ignore
const EventUpdate = () => import('@/entities/event/event-update.vue');
// prettier-ignore
const EventDetails = () => import('@/entities/event/event-details.vue');
// prettier-ignore
const Game = () => import('@/entities/game/game.vue');
// prettier-ignore
const GameUpdate = () => import('@/entities/game/game-update.vue');
// prettier-ignore
const GameDetails = () => import('@/entities/game/game-details.vue');
// prettier-ignore
const License = () => import('@/entities/license/license.vue');
// prettier-ignore
const LicenseUpdate = () => import('@/entities/license/license-update.vue');
// prettier-ignore
const LicenseDetails = () => import('@/entities/license/license-details.vue');
// prettier-ignore
const Machine = () => import('@/entities/machine/machine.vue');
// prettier-ignore
const MachineUpdate = () => import('@/entities/machine/machine-update.vue');
// prettier-ignore
const MachineDetails = () => import('@/entities/machine/machine-details.vue');
// prettier-ignore
const MachineAppConfig = () => import('@/entities/machine-app-config/machine-app-config.vue');
// prettier-ignore
const MachineAppConfigUpdate = () => import('@/entities/machine-app-config/machine-app-config-update.vue');
// prettier-ignore
const MachineAppConfigDetails = () => import('@/entities/machine-app-config/machine-app-config-details.vue');
// prettier-ignore
const MatchConfig = () => import('@/entities/match-config/match-config.vue');
// prettier-ignore
const MatchConfigUpdate = () => import('@/entities/match-config/match-config-update.vue');
// prettier-ignore
const MatchConfigDetails = () => import('@/entities/match-config/match-config-details.vue');
// prettier-ignore
const MatchGame = () => import('@/entities/match-game/match-game.vue');
// prettier-ignore
const MatchGameUpdate = () => import('@/entities/match-game/match-game-update.vue');
// prettier-ignore
const MatchGameDetails = () => import('@/entities/match-game/match-game-details.vue');
// prettier-ignore
const MatchResult = () => import('@/entities/match-result/match-result.vue');
// prettier-ignore
const MatchResultUpdate = () => import('@/entities/match-result/match-result-update.vue');
// prettier-ignore
const MatchResultDetails = () => import('@/entities/match-result/match-result-details.vue');
// prettier-ignore
const MoveGame = () => import('@/entities/move-game/move-game.vue');
// prettier-ignore
const MoveGameUpdate = () => import('@/entities/move-game/move-game-update.vue');
// prettier-ignore
const MoveGameDetails = () => import('@/entities/move-game/move-game-details.vue');
// prettier-ignore
const Payment = () => import('@/entities/payment/payment.vue');
// prettier-ignore
const PaymentUpdate = () => import('@/entities/payment/payment-update.vue');
// prettier-ignore
const PaymentDetails = () => import('@/entities/payment/payment-details.vue');
// prettier-ignore
const Region = () => import('@/entities/region/region.vue');
// prettier-ignore
const RegionUpdate = () => import('@/entities/region/region-update.vue');
// prettier-ignore
const RegionDetails = () => import('@/entities/region/region-details.vue');
// prettier-ignore
const TurnGame = () => import('@/entities/turn-game/turn-game.vue');
// prettier-ignore
const TurnGameUpdate = () => import('@/entities/turn-game/turn-game-update.vue');
// prettier-ignore
const TurnGameDetails = () => import('@/entities/turn-game/turn-game-details.vue');
// prettier-ignore
const AnnouncementViewLog = () => import('@/entities/announcement-view-log/announcement-view-log.vue');
// prettier-ignore
const AnnouncementViewLogUpdate = () => import('@/entities/announcement-view-log/announcement-view-log-update.vue');
// prettier-ignore
const AnnouncementViewLogDetails = () => import('@/entities/announcement-view-log/announcement-view-log-details.vue');
// prettier-ignore
const Campaing = () => import('@/entities/campaing/campaing.vue');
// prettier-ignore
const CampaingUpdate = () => import('@/entities/campaing/campaing-update.vue');
// prettier-ignore
const CampaingDetails = () => import('@/entities/campaing/campaing-details.vue');
// prettier-ignore
const CampaingAnnouncement = () => import('@/entities/campaing-announcement/campaing-announcement.vue');
// prettier-ignore
const CampaingAnnouncementUpdate = () => import('@/entities/campaing-announcement/campaing-announcement-update.vue');
// prettier-ignore
const CampaingAnnouncementDetails = () => import('@/entities/campaing-announcement/campaing-announcement-details.vue');
// prettier-ignore
const Award = () => import('@/entities/award/award.vue');
// prettier-ignore
const AwardUpdate = () => import('@/entities/award/award-update.vue');
// prettier-ignore
const AwardDetails = () => import('@/entities/award/award-details.vue');
// prettier-ignore
const AwardLicense = () => import('@/entities/award-license/award-license.vue');
// prettier-ignore
const AwardLicenseUpdate = () => import('@/entities/award-license/award-license-update.vue');
// prettier-ignore
const AwardLicenseDetails = () => import('@/entities/award-license/award-license-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'announcement',
      name: 'Announcement',
      component: Announcement,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement/new',
      name: 'AnnouncementCreate',
      component: AnnouncementUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement/:announcementId/edit',
      name: 'AnnouncementEdit',
      component: AnnouncementUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement/:announcementId/view',
      name: 'AnnouncementView',
      component: AnnouncementDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-license',
      name: 'AnnouncementLicense',
      component: AnnouncementLicense,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-license/new',
      name: 'AnnouncementLicenseCreate',
      component: AnnouncementLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-license/:announcementLicenseId/edit',
      name: 'AnnouncementLicenseEdit',
      component: AnnouncementLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-license/:announcementLicenseId/view',
      name: 'AnnouncementLicenseView',
      component: AnnouncementLicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company',
      name: 'Company',
      component: Company,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country',
      name: 'Country',
      component: Country,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/new',
      name: 'CountryCreate',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/edit',
      name: 'CountryEdit',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/view',
      name: 'CountryView',
      component: CountryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event',
      name: 'Event',
      component: Event,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/new',
      name: 'EventCreate',
      component: EventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/:eventId/edit',
      name: 'EventEdit',
      component: EventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'event/:eventId/view',
      name: 'EventView',
      component: EventDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'game',
      name: 'Game',
      component: Game,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'game/new',
      name: 'GameCreate',
      component: GameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'game/:gameId/edit',
      name: 'GameEdit',
      component: GameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'game/:gameId/view',
      name: 'GameView',
      component: GameDetails,
      meta: { authorities: [Authority.USER] },
    },
    /* {
      path: 'license',
      name: 'License',
      component: License,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/new',
      name: 'LicenseCreate',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/edit',
      name: 'LicenseEdit',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/view',
      name: 'LicenseView',
      component: LicenseDetails,
      meta: { authorities: [Authority.USER] },
    },*/
    {
      path: 'license',
      name: 'License',
      component: License,
      meta: { authorities: [Authority.COMPANY, Authority.ADMIN] },
    },
    {
      path: 'license/new',
      name: 'LicenseCreate',
      component: LicenseUpdate,
      meta: { authorities: [Authority.COMPANY, Authority.ADMIN] },
    },
    {
      path: 'license/:licenseId/edit',
      name: 'LicenseEdit',
      component: LicenseUpdate,
      meta: { authorities: [Authority.COMPANY, Authority.ADMIN] },
    },
    {
      path: 'license/:licenseId/view',
      name: 'LicenseView',
      component: LicenseDetails,
      meta: { authorities: [Authority.COMPANY, Authority.ADMIN] },
    },
    ////////////////////////
    {
      path: 'machine',
      name: 'Machine',
      component: Machine,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine/new',
      name: 'MachineCreate',
      component: MachineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine/:machineId/edit',
      name: 'MachineEdit',
      component: MachineUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine/:machineId/view',
      name: 'MachineView',
      component: MachineDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine-app-config',
      name: 'MachineAppConfig',
      component: MachineAppConfig,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine-app-config/new',
      name: 'MachineAppConfigCreate',
      component: MachineAppConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine-app-config/:machineAppConfigId/edit',
      name: 'MachineAppConfigEdit',
      component: MachineAppConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'machine-app-config/:machineAppConfigId/view',
      name: 'MachineAppConfigView',
      component: MachineAppConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-config',
      name: 'MatchConfig',
      component: MatchConfig,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-config/new',
      name: 'MatchConfigCreate',
      component: MatchConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-config/:matchConfigId/edit',
      name: 'MatchConfigEdit',
      component: MatchConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-config/:matchConfigId/view',
      name: 'MatchConfigView',
      component: MatchConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-game',
      name: 'MatchGame',
      component: MatchGame,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-game/new',
      name: 'MatchGameCreate',
      component: MatchGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-game/:matchGameId/edit',
      name: 'MatchGameEdit',
      component: MatchGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-game/:matchGameId/view',
      name: 'MatchGameView',
      component: MatchGameDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-result',
      name: 'MatchResult',
      component: MatchResult,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-result/new',
      name: 'MatchResultCreate',
      component: MatchResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-result/:matchResultId/edit',
      name: 'MatchResultEdit',
      component: MatchResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match-result/:matchResultId/view',
      name: 'MatchResultView',
      component: MatchResultDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'move-game',
      name: 'MoveGame',
      component: MoveGame,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'move-game/new',
      name: 'MoveGameCreate',
      component: MoveGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'move-game/:moveGameId/edit',
      name: 'MoveGameEdit',
      component: MoveGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'move-game/:moveGameId/view',
      name: 'MoveGameView',
      component: MoveGameDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'payment',
      name: 'Payment',
      component: Payment,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'payment/new',
      name: 'PaymentCreate',
      component: PaymentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'payment/:paymentId/edit',
      name: 'PaymentEdit',
      component: PaymentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'payment/:paymentId/view',
      name: 'PaymentView',
      component: PaymentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region',
      name: 'Region',
      component: Region,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/new',
      name: 'RegionCreate',
      component: RegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/:regionId/edit',
      name: 'RegionEdit',
      component: RegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/:regionId/view',
      name: 'RegionView',
      component: RegionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'turn-game',
      name: 'TurnGame',
      component: TurnGame,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'turn-game/new',
      name: 'TurnGameCreate',
      component: TurnGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'turn-game/:turnGameId/edit',
      name: 'TurnGameEdit',
      component: TurnGameUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'turn-game/:turnGameId/view',
      name: 'TurnGameView',
      component: TurnGameDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-view-log',
      name: 'AnnouncementViewLog',
      component: AnnouncementViewLog,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-view-log/new',
      name: 'AnnouncementViewLogCreate',
      component: AnnouncementViewLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-view-log/:announcementViewLogId/edit',
      name: 'AnnouncementViewLogEdit',
      component: AnnouncementViewLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'announcement-view-log/:announcementViewLogId/view',
      name: 'AnnouncementViewLogView',
      component: AnnouncementViewLogDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing',
      name: 'Campaing',
      component: Campaing,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing/new',
      name: 'CampaingCreate',
      component: CampaingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing/:campaingId/edit',
      name: 'CampaingEdit',
      component: CampaingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing/:campaingId/view',
      name: 'CampaingView',
      component: CampaingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing-announcement',
      name: 'CampaingAnnouncement',
      component: CampaingAnnouncement,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing-announcement/new',
      name: 'CampaingAnnouncementCreate',
      component: CampaingAnnouncementUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing-announcement/:campaingAnnouncementId/edit',
      name: 'CampaingAnnouncementEdit',
      component: CampaingAnnouncementUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'campaing-announcement/:campaingAnnouncementId/view',
      name: 'CampaingAnnouncementView',
      component: CampaingAnnouncementDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award',
      name: 'Award',
      component: Award,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award/new',
      name: 'AwardCreate',
      component: AwardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award/:awardId/edit',
      name: 'AwardEdit',
      component: AwardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award/:awardId/view',
      name: 'AwardView',
      component: AwardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award-license',
      name: 'AwardLicense',
      component: AwardLicense,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award-license/new',
      name: 'AwardLicenseCreate',
      component: AwardLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award-license/:awardLicenseId/edit',
      name: 'AwardLicenseEdit',
      component: AwardLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'award-license/:awardLicenseId/view',
      name: 'AwardLicenseView',
      component: AwardLicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
